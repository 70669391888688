header {
  background: url(../layout/images/header-banner.jpg) no-repeat top center;
}

.logo_header {
  img {
    width: 82px;
  }
}

header .logo {
  padding: 4px 0;
  width: 116px;
  img {
    border-radius: 50%;
    box-shadow: 3px 3px 20px #4DB269;
  }
}

.container {
  max-width: 1200px !important;
  padding: 0 15px !important;
}

.header-title {
  margin-top: 10px;
  margin-left: 10px;
  font-size: 17px;
  font-weight: bold;
  color: #002a80;
}

.header-title .title1 {
  font-size: 14px;
  font-weight: bold;
  color: #002a80;
}

.header-container {

  .navbar {
    padding: 0rem 1rem;

    .navbar-brand {
      margin-right: 0;

      .logo {
        width: 3.125rem;
        object-fit: cover;
      }
    }

    ul.navbar-nav {
      li.nav-item {
        &.padding-left-menu {
          padding-left: 0.5rem;
        }

        a.nav-link {
          font-weight: 500;
          font-size: 0.875rem;
          color: #ffffff;
          text-transform: uppercase;
          padding: 0;

          @media (min-width: 1400px) {
            font-size: 0.9375rem;
          }

          &.active {
            color: #2fff92;
          }
        }

        &.dropdown {
          .dropdown-menu {
            margin-top: -0.2875rem;
            background-color: transparent;
            border: 0;

            @media (max-width: 575px) {
              margin-top: 0;
              padding-top: 0;
            }

            @media (min-width: 768px) and (max-width: 991px) {
              margin-top: 0;
              padding-top: 0;
            }

            a {
              background-color: #00b6deb2;
              border-bottom: 1px solid white;
              padding: 0.5rem 1rem;
              font-size: 14px;

              &:hover {
                color: #2fff92;
              }
            }
          }
        }
      }
    }
  }

  .dropdown-item {
    cursor: pointer;
  }
}

@media (min-width: 1400px) {
  .padding-left-menu {
    padding-left: 3rem !important;
  }
}

@media (min-width: 992px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0rem);
    opacity: 1;
  }

  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: none;
    -webkit-opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }

  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

#mobile-account-link {
  position: relative;
  top: -17px;
  left: 20px;
  display: none;
}

@media (max-width: 767px) {
  .main-nav {
    height: 44px;
  }

  .navbar-toggler {
    margin-left: -15px;
    margin-top: 3px;
  }

  #navbarNav {
    position: absolute;
    z-index: 99999;
    background-color: #1883db;
    border: solid 1px #CCCCCC;
    margin-left: -15px;
    top: 40px;
    width: 100%;
  }

  .main-nav ul li ul.level1 {
    width: 100% !important;
  }

  .level1_mobile {
    max-width: 100% !important;
    width: 100% !important;
  }



  // #home-banner {
  //   display: none;
  // }

  #mobile-account-link {
    display: block;
  }

  .container {
    max-width: 100% !important;
  }
}

#dropdown_language {
  background-color: #2049EF !important;
  margin-top: 8px;
  //border: 0 !important;
}

.language_item {
  color: #000 !important;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
}

.img_language {
  width: 30px;
  margin-right: 1rem !important;
}