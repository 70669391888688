.introduce {
    .card__item {
        margin-left: -5px;
        margin-right: -5px;
        background-color: #F8FCFF;
        border-radius: 3px;
        padding: 50px 30px 64px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        color: #333333;
        cursor: pointer;
        margin-bottom: 20px;
        transition: 0.2s;
        border: 1px solid #CCCCCC;
        min-height: 324px;

        &:hover {
            background-color: #1a77c7;
            color: #ffffff;

            svg {
                path {
                    fill: #fff;
                }
            }
        }

        .card__img {
            max-width: 68px;
            margin-bottom: 20px;

            svg {
                transition: .2s;
            }
        }

        .card__body {
            p {
                font-size: 14px;
                font-weight: 300;
            }

            span {
                display: block;
                font-weight: 700;
                font-size: 1.875rem;
            }

            h3 {
                font-size: 1.125rem;
                font-weight: 400;
            }
        }
    }

    .video-ytb {
        margin-top: 40px;
        margin-left: -5px;
        margin-right: -5px;
        margin-bottom: 70px;

        iframe {
            width: 100%;
            height: 615px;
        }
    }
}