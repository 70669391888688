.news {
  background: #ffffff;
}

.news .new-nav .nav-action {
  cursor: pointer;
}

.new-image {
  text-align: center
}

// .news h3 {
//   font-weight: 500;
//   font-size: 24px;
//   line-height: 20px;
//   color: #3342B9;
//   border-left: 3px solid #FFAF18;
//   padding-left: 5px;
//   margin-bottom: 20px;
// }

.news .new-nav {
  text-align: right;
}

.news .new-nav .nav-action {
  display: inline-block;
  width: 22px;
  line-height: 22px;
  height: 22px;
  border: 1px solid #333333;
  text-align: center;
  margin-left: 5px;
}

.news .new-nav .nav-action svg {
  vertical-align: middle;
  height: 20px;
}

.news .hot-new {
  margin-bottom: 30px;
  // margin-top:10px;
}

.news .hot-new .new-image {
  text-align: center;
}

.news .hot-new .new-image img {
  width: 100%;
  border-radius: 5px;
}

.news .hot-new .new-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #333333;
  display: block;
  margin: 10px 0 5px;
}

.news .hot-new .new-date {
  font-size: 16px;
  line-height: 19px;
  color: #A4A4A4;
  margin-bottom: 10px;
}

.news .hot-new .new-desc {
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 16px;
  text-align: justify;
  font-style: italic;
}

.news .hot-new .view-detail {
  color: #3342B9;
  font-size: 16px;
  line-height: 19px;
}

.news .hot-new .view-detail svg {
  vertical-align: unset;
  margin-left: 5px;
}

.news .hot-new .view-detail:hover {
  text-decoration: none;
}

.news .other-news {
  margin-bottom: 30px;
}

.news .other-news .new-item {
  margin-bottom: 20px;
}

.news .other-news .new-item:after {
  content: "";
  display: block;
  clear: both;
}

.news .other-news .new-item:last-child {
  margin-bottom: 0;
}

.news .other-news .new-item .new-image {
  width: 200px;
  height: 110px;
  overflow: hidden;
  float: left;
  border-radius: 3px;
}

.news .other-news .new-item .new-image img {
  max-width: 100%;
  border-radius: 3px;
}

.news .other-news .new-item .new-info {
  margin-left: 220px;
}

.news .other-news .new-item .new-info .new-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #333;
  display: block;
  margin-bottom: 6px;
}

.news .other-news .new-item .new-info .new-date {
  font-size: 16px;
  line-height: 19px;
  color: #A4A4A4;
  margin-bottom: 28px;
}

.news .other-news .new-item .new-info .view-detail {
  color: #3342B9;
  font-size: 16px;
  line-height: 19px;
}

.news .other-news .new-item .new-info .view-detail svg {
  vertical-align: unset;
  margin-left: 5px;
}

.news .other-news .new-item .new-info .view-detail:hover {
  text-decoration: none;
}

.home_news {
  padding: 50px 0 70px;

  .header_home_title {
    margin-bottom: 1rem;
    border-bottom: 3px solid #cccccc;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      position: relative;

      h3 {
        font-size: 1.5rem;
      }

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 3px;
        top: 100%;
        left: 0;
        background: linear-gradient(90deg, #02297e 0%, #1a77c7 50%, #c2e20c 100%);
        background-repeat: no-repeat;
      }
    }

    .see_more {
      font-size: 1.125rem;
      color: #1a77c7;
      white-space: nowrap;
      transition: 0.2s;
      text-decoration: none;

      img {
        margin-left: 5px;
      }

      &:hover {
        color: #5691c6;
      }
    }
  }

  .home_new_content {
    margin-top: 2rem;
  }
}

.news_card {
  background-color: #f1f1f1;
  min-height: 200px;
  max-height: 406px;
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  border-bottom: 3px solid #1a77c7;
  border-radius: 2px;
  box-shadow: 0 0 5px #cdcdcd;

  img {
    width: 100%;
    height: 37%;
    min-height: 150px;
    object-fit: cover;
    transition-duration: .3s;

    &:hover {
      transform: scale(1.05);
      opacity: 0.7;
    }
  }

  .title {
    margin: 1rem;
    display: block;
    font-size: 1rem;
    font-weight: 500;
    color: #333333;
    margin-bottom: 10px;
    line-height: 1.4;
    transition: 0.2s;
    text-decoration: none;

    &:hover {
      opacity: .7;
    }
  }

  .description {
    margin: 0 1rem 1rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.6;
    color: #333333;
  }
}

@media (max-width: 767px) {
  #home-other-news .new-image img {
    height: auto;
  }

  #home-other-news .new-info {
    width: 100%;
    margin: auto !important;
    float: left;
  }

  #home-other-news .new-info .news-create-date {
    font-size: 16px;
  }

  #home-other-news .view-detail {
    float: right;
    margin-right: 10px;
  }
}