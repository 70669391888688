.breadcrumb_ {
    padding: 37px 0;
    margin: 0;
    margin-bottom: 30px;
    background-color: #F1F1F1;
    display: flex;
    flex-wrap: wrap;

    .breadcrumb-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
    }

    .current {
        font-size: 1.375rem;
        font-weight: 600;
        line-height: 27px;
    }

    .breadcrumb-main {
        display: flex;
        justify-content: start;
        align-items: center;
        height: 26px;

        .breadcrumb-item {
            font-size: 1rem;
            font-weight: 400;
            line-height: 19px;
            color: #656565;
            text-decoration: none;
        }

        .breadcrumd-icon {
            margin: 0 9px 0;
        }
    }
}