.detail-title {
    margin-bottom: 10px;
}

.detail-title .news-title {
    font-weight: bold;
    font-size: 22px;
    color: #1d2124;
}

.news .hot-new .new-date {
    margin-top: 10px;
}

.news .other-news .new-item .new-info .view-detail {
    color: #2049EF;
}

.news-image {
    width: 100%;
    position: relative;

    img {
        width: 100%;
        max-height: 700px;
        display: block;
    }

    h3 {
        position: absolute;
        bottom: 30px;
        left: 30px;
        right: 30px;
        font-size: 22px;
        font-weight: 600;
        color: #fff;
        padding: 22px;
        background-color: #000000B2;
        text-align: center;
        border-bottom: 2px solid #C1E20C;
        line-height: 1.4;
    }
}