footer {
  background: #f1f1f1;
  padding-top: 30px;

  .top-main {
    display: flex;
    justify-content: start;
    align-items: flex-start;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    .logo {
      width: 130px;
      margin-right: 60px;
    }

    .info {
      flex: 1;
      display: flex;
      justify-content: start;
      align-items: flex-start;

      img {
        margin-right: 5px;
        margin-top: 3px;
      }

      p {
        font-size: 1rem;
        font-weight: 300;
        color: #333333;
        line-height: 26px;
      }

      .info-item {
        &.place {
          display: flex;
          justify-content: start;
          align-items: flex-start;
          margin-right: 80px;

          p {
            max-width: 477px;
          }

          @media (max-width: 1024px) {
            margin-right: 30px;
          }
        }

        &.contact {
          display: flex;
          justify-content: start;
          align-items: flex-start;
          flex-flow: row wrap;

          .phone {
            margin-right: 50px;
          }

          .contact-item {
            display: flex;
            justify-content: start;
            align-items: flex-start;

          }

          @media (max-width: 1024px) {
            flex-flow: column wrap;
          }
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
  }
}

footer .name {
  padding: 25px 0;
}

footer .name:after {
  clear: both;
  display: block;
  content: "";
}

footer .name img {
  float: left;
  margin-right: 15px;
  margin-top: 5px;
}

footer .name h3 {
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
  margin: 12px 0 0;
}

footer .detail {
  padding-top: 33px;
}

footer .detail p {
  margin-bottom: 2px;
  font-size: 16px;
  line-height: 26px;
}

footer .detail p svg {
  width: 14px;
  margin-right: 6px;
  vertical-align: middle;
}

footer .copyright {
  border-top: 1px solid #fff;
  padding: 12px 0;
  font-size: 14px;
  line-height: 16px;
}

.copyright {
  background: linear-gradient(90deg, #02297e 0%, #1a77c7 50%, #c2e20c 100%);
  padding: 13px 0;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 17px;
}